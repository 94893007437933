import React, { ReactElement } from 'react';
import styled from 'styled-components';

const GreyBannerWrapper = styled.div<{noMargin ?: boolean}>`
  background-color: #F5F5F5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .75rem; 
  font-weight: bold;
  border: 1px solid #D2D2D2;
  border-bottom: 2px solid #0060A9;
  border-radius: 6px 6px 0 0;
  margin-bottom: ${props => props.noMargin ? '0' : '1rem'};
  
`

type GreyBannerProps = {
    title : string,
    button ?: ReactElement,
    noMargin ?: boolean
}
export default function GreyBanner(props : GreyBannerProps) {
    return(
        <GreyBannerWrapper noMargin = {props.noMargin}>{props.title}{props.button}</GreyBannerWrapper>
    )
}