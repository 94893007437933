import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {useAuth} from "../auth/AuthProvider";
import AuthResponse from "../models/AuthResponse";
import {useAxios} from "../AxiosProvider";
import {AxiosError, AxiosResponse} from "axios";
import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import {Input} from "./form/FormElements";
import {useMessageService} from "../hooks/useMessageService";
import {Form, Label, PrimaryButton} from "../styledcomponents/FormStyledComponents";
import {useNavigate} from "react-router-dom";
import {ResponseCode} from "../enums/ResponseCode";
import {Page, usePath} from "../PathProvider";
import {InlineLink, PageTitle, StyledLink} from '../styledcomponents/MiscStyledComponents';
import ErrorUtils from "../utils/errorUtils";
import {ContactUsLink} from "./ContactUsLink";
import GreyBanner from './GreyBanner';
import { USER_GUIDE_URL, NJ_USER_GUIDE_URL } from '../utils/urlUtils';

const LoginFormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: .5rem;
`

const LoginWrapper = styled.div`
    display: grid;
    width: 100%;
    gap: 3rem;
    grid-auto-flow: column;
    @media (max-width: 768px) {
      grid-auto-flow: row;
    }
`

const LoginContent = styled.div`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    margin-bottom: 15px;
`

const LoginWidget = styled.div`
    font-family: 'Roboto';
    display: flex;
    border: 2px solid #D2D2D2;
    border-radius: 6px;
`

const AccountLogInwidget = styled.div`
    clear: both;
    width: 50%;
    padding: 30px;
`

const AccountLogInMain = styled.div`
    display: flex;
`

const AccountLogIn = styled.div`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    display: flex;
    align-items: center;
    padding-left: 15px;
    color: #00477D;
`
const LogInFormwidget = styled.div`
    clear: both;
    width: 50%;
    padding: 35px;
    background: #E6F2FA;
`

const AccountLogInContent = styled.div`
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    padding-top: 10px;
`

const PaddedStyledLink = styled(StyledLink)`
    padding-right : 5rem;
`

export const PrivacyLink = () => <StyledLink href={"https://www.firstenergycorp.com/corporate/privacy-notice.html"} target={"_blank"} style={{display : 'contents'}}>Privacy Notice</StyledLink>

type LoginFormData = {
    username: string,
    password: string,
};

function LoginForm() {

    // Hooks
    const auth = useAuth();
    const navigate = useNavigate();
    const axios = useAxios();
    const messageService = useMessageService();
    const path = usePath();

    // useForm hooks
    const methods = useForm<LoginFormData>({});
    const {handleSubmit, formState : {isSubmitting, isDirty}} = methods;

    // Form state handlers
    const [loginDisabled, setLoginDisabled] = useState<boolean>(true);

    // useEffects
    useEffect(() => {
        setLoginDisabled(isSubmitting || !isDirty);
    }, [isSubmitting, isDirty]);

    // Functions
    const onSubmit : SubmitHandler<LoginFormData> = async (data : LoginFormData) => {
        // clear any messages once we submit
        messageService.clearAll();

        await axios?.openApi.post(
            '/api/login',
            { username: data.username, password: data.password }
        ).then((response : AxiosResponse) => {
            const authResponse : AuthResponse = response.data;

            auth.login(authResponse);
        }).catch((err : AxiosError) => {
            console.error(err);

            let errorResponse : AxiosResponse | undefined = err.response;

            switch (errorResponse?.status) {
                case 400 :
                    messageService.error(ErrorUtils.getValidationErrors(errorResponse))
                    break;
                case 409 :
                    switch (errorResponse?.data.message) {
                        case ResponseCode.ForcePasswordReset :
                            navigate(path.get(Page.ForcedResetPassword), { state : { warningMessage : 'Password expired. Please change your password.' }});
                            break;
                        default:
                            messageService.error(ErrorUtils.UNAUTHORIZED_ERROR);
                    }
                    break;
                default :
                    messageService.error(ErrorUtils.UNKNOWN_ERROR);
            }
        });
    };

    return(
        <LoginFormWrapper>
            <FormProvider {...methods} >
                <PageTitle>Energy Benchmarking Program</PageTitle>

                <GreyBanner title={"Benchmarking your Building"}/>

                <LoginContent>
                    <b>Connect with ENERGY STAR® Portfolio Manager® to Check Your Energy Efficiency.</b><br/><br/>
                    FirstEnergy has developed a benchmarking tool that enables building owners in New Jersey and
                    Maryland to request whole building aggregated data.<br/><br/>
                    Each state has determined the requirements for using this tool.
                    Please see the benchmarking page for the requirements specific to each state for additional information.<br/><br/>
                    <PaddedStyledLink href={NJ_USER_GUIDE_URL} target={"_blank"}>NJ Benchmarking Portal</PaddedStyledLink>&nbsp;
                    <PaddedStyledLink href={USER_GUIDE_URL} target={"_blank"}>MD Benchmarking Portal</PaddedStyledLink>
                </LoginContent>

                <LoginWidget>
                    <AccountLogInwidget>
                        <AccountLogInMain>
                            <div>
                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.6" d="M12.1255 2.40137C6.77785 2.40137 2.42456 6.70937 2.42456 12.0014C2.42456 14.1854 3.17638 16.1894 4.41325 17.7974C6.14729 15.7094 10.3551 15.0014 12.1255 15.0014C13.8959 15.0014 18.1037 15.7094 19.8377 17.7974C21.0746 16.1894 21.8264 14.1854 21.8264 12.0014C21.8264 6.70937 17.4731 2.40137 12.1255 2.40137ZM12.1255 13.2014C9.77302 13.2014 7.88134 11.3294 7.88134 9.00137C7.88134 6.67337 9.77302 4.80137 12.1255 4.80137C14.478 4.80137 16.3697 6.67337 16.3697 9.00137C16.3697 11.3294 14.478 13.2014 12.1255 13.2014Z" fill="#AED3ED" />
                                    <path d="M12.1262 0C5.43252 0 0 5.376 0 12C0 18.624 5.43252 24 12.1262 24C18.8198 24 24.2523 18.624 24.2523 12C24.2523 5.376 18.8198 0 12.1262 0ZM6.14797 19.536C6.66939 18.456 9.84645 17.4 12.1262 17.4C14.4059 17.4 17.5951 18.456 18.1044 19.536C16.4552 20.832 14.3816 21.6 12.1262 21.6C9.8707 21.6 7.79713 20.832 6.14797 19.536ZM19.8384 17.796C18.1044 15.708 13.8966 15 12.1262 15C10.3557 15 6.14797 15.708 4.41393 17.796C3.17706 16.188 2.42523 14.184 2.42523 12C2.42523 6.708 6.77853 2.4 12.1262 2.4C17.4738 2.4 21.8271 6.708 21.8271 12C21.8271 14.184 21.0753 16.188 19.8384 17.796ZM12.1262 4.8C9.77369 4.8 7.88201 6.672 7.88201 9C7.88201 11.328 9.77369 13.2 12.1262 13.2C14.4786 13.2 16.3703 11.328 16.3703 9C16.3703 6.672 14.4786 4.8 12.1262 4.8ZM12.1262 10.8C11.1197 10.8 10.3072 9.996 10.3072 9C10.3072 8.004 11.1197 7.2 12.1262 7.2C13.1326 7.2 13.9451 8.004 13.9451 9C13.9451 9.996 13.1326 10.8 12.1262 10.8Z" fill="#0060A9" />
                                </svg>
                            </div>
                            <AccountLogIn>
                                Account Log In
                            </AccountLogIn>
                        </AccountLogInMain>
                        <AccountLogInContent>
                            Log in to your ENERGY STAR® Portfolio Manager® account to request building data.
                        </AccountLogInContent>
                        <AccountLogInContent>
                            <InlineLink to={path.get(Page.ForgotCredentials)}>Forgot Username or Password?</InlineLink>
                        </AccountLogInContent>
                        <AccountLogInContent>
                            Don't have an online account? <InlineLink to={path.get(Page.Register)}>Register here</InlineLink>.
                        </AccountLogInContent>
                        <AccountLogInContent>
                            If you are having trouble, please <ContactUsLink />.
                        </AccountLogInContent>
                        
                        <p><br/><PrivacyLink/></p>

                    </AccountLogInwidget>
                    <LogInFormwidget>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Label htmlFor={"username"}>Username</Label>
                            <Input name={"username"} />
                            <Label htmlFor={"password"}>Password</Label>
                            <Input type="password" name={"password"} />
                            
                            <PrimaryButton type={"submit"} disabled={loginDisabled} float='left'>
                                {isSubmitting ? 'Logging in...' : 'LOG IN'}</PrimaryButton>
                                
                        </Form>
                    </LogInFormwidget>
                </LoginWidget>
            </FormProvider>
        </LoginFormWrapper>
    )
}

function Login() {
    const auth = useAuth();
    if (auth.user) { auth.logout(); }

    return(
        <LoginWrapper>
            <LoginForm/>
        </LoginWrapper>
    )
}

export default Login;