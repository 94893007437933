import React from 'react';
import {useAuth} from "../auth/AuthProvider";
import styled from "styled-components";
import {NavLink} from "react-router-dom";
import {Page, usePath} from "../PathProvider";
import {StyledLink} from "../styledcomponents/MiscStyledComponents";
import { CONTACT_US_URL } from '../utils/urlUtils';
import { PrivacyLink } from './Login';

const SideNavList = styled.ul`
    list-style: none;
    margin-right: 55px;
    padding: 0;
    margin-top: 0;
`

const SideNavListItem = styled.li<{isActive ?: boolean}>`
    border-bottom: 1px solid #e3e3e3;
    color: #0060a9;
    transition: 0.25s ease-in-out;
    padding: 5px 0;
    cursor: pointer;
    ${props => props.isActive ? 'font-weight: bold;' : ''}
    &:hover {
      color: #000;
      text-decoration: underline;
    }
`
const StyledNavLink = styled(NavLink)`
    text-decoration: none;
    &:hover {
      color: #000;
      text-decoration: underline;
    }
    &:visited {
      color: #0060a9;
    }
`
type NavItemProps = {
    to : string;
    text : string;
}

const SideNavContactUsItem = styled.li`
    border-bottom: 1px solid #e3e3e3;
    color: #0060a9;
    transition: 0.25s ease-in-out;
    padding: 5px 0;
    cursor: pointer;
    &:hover {
      color: #000;
      text-decoration: underline;
    }
`

function NavItem(props : NavItemProps) {
    return (
        <StyledNavLink to={props.to}>
            {({ isActive }) => (
                <SideNavListItem isActive={isActive}>
                    {props.text}
                </SideNavListItem>
            )}
        </StyledNavLink>
    )
}

function ContactUsNavItem() {
    return (
        <SideNavContactUsItem>
            <StyledLink href={CONTACT_US_URL} target={"_blank"} style={{display : 'contents'}}>Contact Us</StyledLink>
        </SideNavContactUsItem>
    )
}

export function PrivacyNavItem() {
    return (
        <SideNavContactUsItem>
            <PrivacyLink/>
        </SideNavContactUsItem>
    )
}


function SideNav() {
    const auth = useAuth();
    const path = usePath();

    return(
        <SideNavList>
            {/*Admin only links*/}
            {
                auth.user?.admin &&
                <React.Fragment>
                    <NavItem to={path.get(Page.AdminViewBuildings)} text={'Buildings'} />
                    <NavItem to={path.get(Page.AdminViewUsers)} text={'Users'} />
                    <NavItem to={path.get(Page.AuditLog)} text={'Activity Log'} />
                    <ContactUsNavItem/>
                </React.Fragment>
            }
            {/*User only links*/}
            {
                !auth.user?.admin &&
                <React.Fragment>
                    <NavItem to={path.get(Page.NonAdminViewBuildings)} text={'Buildings'} />
                    <NavItem to={path.get(Page.ManageAccount)} text={'Manage My Account'} />
                    <NavItem to={path.get(Page.ResetPassword)} text={'Reset Password'} />
                    <ContactUsNavItem/>
                    
                </React.Fragment>
            }
            <br />
            <br />
            <PrivacyNavItem/>
            <SideNavListItem onClick={() => auth.logout()}>Logout</SideNavListItem>
        </SideNavList>
    )
}

export default SideNav;